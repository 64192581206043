@import "../../global"

.header-bar
    padding: 1vh 1em 1vh 1em
    width: 100vw
    height: auto
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    background-color: rgba(#141414,.5)
    border-bottom: 3px
    gap: .5em
    text-align: center
    border-bottom: 2px dashed white

.logo
    width: auto
    height: 4em
    @media (max-width: 600px)
        width: 6em
        height: auto

.logbutton
    width: 12em
    height: 2.5em
    font-family: 'Courier New', Courier, monospace
    font-size: 1em
    font-weight: bold
    border-radius: .5em
    background-color: #ffde59
    color: black
    text-transform: capitalize
    transition-duration: 1s
    padding: 0 1em 0 1em
    letter-spacing: .05em
    &:hover
        background-color: rgba(#FF4B2E,1 )
        color: white
    @media (max-width: 600px)
        width: 8em
        min-height: 2.5em
        font-size: .9em
        font-weight: bolder

.child
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: center
    gap: .5em
    font-size:1.2em

.row-flex
    @include flex-props-row-center
    gap: 1em
    