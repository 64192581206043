@import "../../global"

.preloader
  @include flex-props-column-center
  position: relative

  &-text
    margin-top: 4vh
    font-family: Alice
    font-weight: bold
    font-size: 2.5vw
    color: white
    right: 1.5vw
    padding: 1vw
    background-color: rgba(0,0 ,0 ,0.6)
    border-radius: 10px

  .custom-loader
    width: 10vw
    height: 5vh
    color: #000000
    background: linear-gradient(currentColor 0 0) center/100% 4px, linear-gradient(currentColor 0 0) center/4px 100%, radial-gradient(farthest-side, #0000 calc(100% - 6px), currentColor calc(100% - 5px)), radial-gradient(circle 6px, currentColor 94%, #0000 0)
    background-repeat: no-repeat
    animation: custom-loader 2s infinite linear
    position: relative

    &:before
      content: ""
      position: absolute
      inset: 0
      border-radius: inherit
      background: inherit
      transform: rotate(45deg)


@keyframes custom-loader
  to
    transform: rotate(.5turn)