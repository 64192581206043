@import "../../global"

.main-content
  @include image-background
  font-family: 'Oswald', sans-serif
  height: 100vh

.wrapper
  position: absolute 
  width: 30vw
  height: auto
  opacity: .6
  filter: blur(5px)
  -webkit-filter: blur(5px)
  @media (max-width: 600px)
    width: 70vw
