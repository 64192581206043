@import '../../global'

.menu-child
    width: 100%
    height: 100%
    padding: 1vh 5vw 5em 5vw
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    gap: 1.5em
    @media (max-width: 600px)
        padding: 1vh 2vw 10vh 2vw