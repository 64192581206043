@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@500&family=Oswald:wght@300;400&display=swap')

.tableContainer
    max-width: 70vw
    @media (max-width: 600px)
        max-width: none

.nft
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start
    gap: .25em
    padding: .5em
    width: 100%
    color: white
    border-radius: .5em
    cursor: pointer

.reset
    font-family: 'Orbitron'
    font-size: 1em
    border: 1px solid white
    background: transparent
    color: white
    border-radius: .5em
    transition-duration: 1s
    &:hover
        background: rgba(255,255,255,0.845)
        color: black
        cursor: pointer

.add
    font-family: 'Orbitron'
    font-size: 1.2em
    border: 1px solid white
    background: transparent
    color: white
    border-radius: .5em
    transition-duration: 1s
    width: 100%
    height: 100%
    &:hover
        background: rgba(255,255,255,0.845)
        color: black
        cursor: pointer

.rarity-palette
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    width: 100%
    height: 2em
    border: 2px solid black
    gap: 0.25em
    padding: 0.1em
    border-radius: 0.5em
    background-color: rgba(0, 0, 0, 1)

.palette
    flex: 1
    width: calc(100% / var(--num-palletes))
    height: 100%
    border: none
    border-width: 3px
    border-style: solid
    border-color: white
    border-radius: .4em
    &.rarity
        border: none

.tablecell-parent
    height: 7em
.paddingClass
    max-width: 15em
    border: 3px solid black
.nft-template-container
    display: grid
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr))
    grid-gap: 10px
    width: 100%
    max-height: calc( 90vh - 40vh )
    padding: 1vh 5vw 5em 5vw
    overflow: auto
    font-size: 1em
    text-align: center
    @media (max-width: 600px)
        padding: 1vh 2vw 10vh 2vw
    .nft-template
        height: auto
        width: auto
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: center
        background-color: transparent
        cursor: pointer
        padding: 0
    .nft-img
        width: 10em
        height: auto
        image-resolution: 300dpi
        background-size: cover
        transition-duration: 1s
        &:hover
            background-color: rgba(255,255,255,1)
            border-radius: .5em
.filter-container
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    gap: 1em
    @media (max-width: 600px)
        flex-direction: column
.formControl
    width: 100%
    height: 3em
    margin: auto
    display: flex
    justify-content: center
    @media (max-width: 600px)
        width: 20%
